import React from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox, FormLabel } from '@material-ui/core';

const CheckboxInput = (props) => {
  const {
    input: { name, onChange, checked },
    label,
  } = props;

  return (
    <Box display="flex" alignItems="flex-start">
      <Box>
        <Checkbox
          color="primary"
          aria-label={label}
          name={name}
          checked={checked}
          onChange={onChange}
          style={{ padding: 0 }}
        />
      </Box>
      <Box mt={2}>
        <FormLabel>{label}</FormLabel>
      </Box>
    </Box>
  );
};

CheckboxInput.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

export default CheckboxInput;
